class MenuModal extends HTMLElement {
    constructor() {
        super();

        this.tab = this.querySelector('.tab');
        this.details = this.querySelector('.menu-detail');
        this.container = this.querySelector('.menu-container');
        this.parentHeader = document.querySelector('header-wrapper');
        this.containerHeight = this.container.firstElementChild.clientHeight;

        this.tab.addEventListener('mouseenter', this.onTabClick.bind(this));
        this.details.addEventListener('keyup', (event) => event.code.toUpperCase() === 'ESCAPE' && this.close());

        this.tab.setAttribute('role', 'button');
    }

    isOpen() {
        return this.details.hasAttribute('open');
    }

    onTabClick(event) {
        event.preventDefault();
        const currentTab = document.querySelector('.menu-detail[open="true"]');
        if(currentTab){
            //if another menu is open
            this.mutliOpen(event);
            //if this tab is open
            event.target.closest('.menu-detail') == currentTab ? event.target.closest('.menu-detail').setAttribute('open', true) : currentTab.closest('menu-modal').multiClose();

        } else {
            //if no menu is open
            event.target.closest('.menu-detail').hasAttribute('open') ? this.close() : this.open(event);
        }
    }

    onBodyClick(event) {
        console.log(event.target);
        if(!event.target.closest('.menu-detail')) this.close();
    }

    mutliOpen(event) {
        this.onBodyClickEvent = this.onBodyClick.bind(this);
        event.target.closest('.menu-detail').setAttribute('open', true);
        event.target.closest('.nav-link').classList.add('active');
        document.body.addEventListener('mouseenter', this.onBodyClickEvent);
        this.parentHeader.addEventListener('mouseleave', this.onBodyClickEvent);
        //document.getElementById('search-modal').classList.remove('border-b');

        //contianer maxheight 0% -> 350px (this is consistent in design)
        this.container.style.height = `${this.containerHeight}px`;

        //opacity 0-1
        this.container.firstElementChild.classList.remove('opacity-0');
        this.container.firstElementChild.classList.add('opacity-1');
        this.container.classList.remove('opacity-0');
        this.container.classList.add('opacity-1');

        //remove height transition property
        this.container.classList.remove('transition-height');
        this.container.classList.add('transition-none');

        //add opacity
        this.container.classList.add('opacity-1');
        
        //WISHLIST TODO:
        //once that animation completes
            //content opacity 0 - 1
            //image maxheight 0 - 100
    }

    open(event) {
        this.onBodyClickEvent = this.onBodyClick.bind(this);
        event.target.closest('.menu-detail').setAttribute('open', true);
        event.target.closest('.nav-link').classList.add('active');
        document.body.addEventListener('mouseenter', this.onBodyClickEvent);
        this.parentHeader.addEventListener('mouseleave', this.onBodyClickEvent);
        //document.getElementById('search-modal').classList.remove('border-b');

        //opacity 0-1
        this.container.firstElementChild.classList.remove('opacity-0');
        this.container.firstElementChild.classList.add('opacity-1');
        this.container.classList.remove('opacity-0');
        this.container.classList.add('opacity-1');

        //contianer maxheight 0% -> 350px (this is consistent in design)
        this.container.style.height = `${this.containerHeight}px`;
        this.container.classList.remove('h-0');

        //add height transition
        this.container.classList.add('transition-height');
        this.container.classList.remove('transition-none');
        
        //WISHLIST TODO:
        //once that animation completes
            //content opacity 0 - 1
            //image maxheight 0 - 100
    }

    close() {
        this.onBodyClickEvent = this.onBodyClick.bind(this);
        this.details.removeAttribute('open');
        this.closest('.nav-link').classList.remove('active');
        document.body.removeEventListener('mouseenter', this.onBodyClickEvent);
        this.parentHeader.removeEventListener('mouseleave', this.onBodyClickEvent);
        //document.getElementById('search-modal').classList.add('border-b');

        //add height transition
        this.container.classList.add('transition-height');
        this.container.classList.remove('transition-none');

        //remove opacity:
        this.container.firstElementChild.classList.remove('opacity-1');
        this.container.firstElementChild.classList.add('opacity-0');
        this.container.classList.add('opacity-0');
        this.container.classList.remove('opacity-1');
        //remove max-h
        this.container.style.height = "0px";
    }

    multiClose() {
        this.onBodyClickEvent = this.onBodyClick.bind(this);
        this.details.removeAttribute('open');
        this.closest('.nav-link').classList.remove('active');
        document.body.removeEventListener('mouseenter', this.onBodyClickEvent);
        this.parentHeader.removeEventListener('mouseleave', this.onBodyClickEvent);
        //document.getElementById('search-modal').classList.add('border-b');

        //add height transition
        this.container.classList.remove('transition-height');
        this.container.classList.add('transition-none');
        this.container.classList.add('opacity-0');
        this.container.classList.remove('opacity-1');


        //remove opacity:
        this.container.firstElementChild.classList.remove('opacity-1');
        this.container.firstElementChild.classList.add('opacity-0');
        //remove max-h
        this.container.style.height = '0px';
    }
}

customElements.define('menu-modal', MenuModal)